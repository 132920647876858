<template>
  <div>
    <custom-text-field-number
      v-if="!customConfig"
      v-model="paxCount"
      :max="max"
      dense
      :label="$t('booking.reservations.numPax', locale)"
      style="padding: 10px"
      @input="handleChange"
    />
    <v-layout wrap v-else>
      <v-flex xs12>
        <custom-text-field-number
          v-if="customConfig.Adult"
          v-model="adults"
          :max="max"
          dense
          :label="$t('booking.reservations.adultsPax', locale)"
          style="padding: 10px"
          @input="handleChange"
        />
      </v-flex>
      <v-flex xs12>
        <custom-text-field-number
          v-if="customConfig.Children"
          v-model="childrens"
          :max="max"
          dense
          :label="$t('booking.reservations.childrensPax', locale)"
          style="padding: 10px"
          @input="handleChange"
        />
      </v-flex>
      <v-flex xs12>
        <custom-text-field-number
          v-if="customConfig.Baby"
          v-model="babies"
          :max="max"
          dense
          :label="$t('booking.reservations.babiesPax', locale)"
          style="padding: 10px"
          @input="handleChange"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from 'axios'
import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
export default {
  components: {
    CustomTextFieldNumber,
  },
  props: {
    eventID: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    oldNumPax: {
      type: Number,
      default: null,
    },
    oldAdult: {
      type: Number,
      default: null,
    },
    oldChildren: {
      type: Number,
      default: null,
    },
    oldBaby: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    customConfig: null,
    paxCount: 0,
    adults: 0,
    childrens: 0,
    babies: 0,
  }),
  watch: {
    eventID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    prepareOldPaxData () {
      if (this.oldNumPax) this.paxCount = this.oldNumPax
      if (this.oldAdult) this.adults = this.oldAdult
      if (this.oldChildren) this.childrens = this.oldChildren
      if (this.oldBaby) this.babies = this.oldBaby
    },
    handleGetData () {
      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/events/${this.eventID}/custom-pax-count-config`
      axios.get(url)
        .then(response => {
          this.customConfig = response.data.shift()
          this.prepareOldPaxData()
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    totalCount () {
      const result = {}
      if (!this.customConfig) {
        result.paxCount = this.paxCount
      } else {
        result.paxCount = 0
        const { Children, ChildrenAsCount, Adult, AdultAsCount, Baby, BabyAsCount } = this.customConfig
        if (Children) {
          result.children = this.childrens
          if (ChildrenAsCount) result.paxCount += this.childrens
        }
        if (Adult) {
          result.adult = this.adults
          if (AdultAsCount) result.paxCount += this.adults
        }
        if (Baby) {
          result.baby = this.babies
          if (BabyAsCount) result.paxCount += this.babies
        }
      }
      return result
    },
    handleChange () {
      const pax = this.totalCount()
      this.onChange(pax)
    }
  },
}
</script>

