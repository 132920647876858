<template>
  <v-layout wrap :class="isOwner ? 'owner' : 'pax'" v-if="values !== {}">
    <v-flex xs12 v-if="isOwner">
      {{ $t('booking.reservations.clientData', locale) }} :
    </v-flex>
    <v-flex xs11 v-else style="padding: 10px 0 0 10px">
      {{ $t('booking.reservations.pax', locale) }} {{ pos !== null ? pos + 1 : '' }}
    </v-flex>
    <v-flex xs1 v-if="onDeletePax">
      <v-spacer />
      <v-btn
        color="red"
        fab
        dark
        small
        top
        right
        text
        @click="onDeletePax(pos)"
      >
        <v-icon>mdi-delete-circle</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs12
      v-for="(field, index) of formFields"
      :key="index"
    >
      <check-info v-if="field.type === 'checkInfo'"
        :field="field"
        :locale="locale"
        :value="values[field.key]"
        :onChange="(v) => { handleCheckInfo(field.key, v) }"
      />
      <v-switch
        style="margin: 0 10px;"
        v-if="field.type === 'switch'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        hide-details
      />
      <v-text-field
        v-if="field.type === 'email'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        :maxlength="field.maxLength"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-text-field
        :key="`text${pos}`"
        v-if="field.type === 'text'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        :maxlength="field.maxLength"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-textarea
        v-if="field.type === 'textArea'"
        v-model="values[field.key]"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        :maxlength="field.maxLength"
        outlined
        clearable
        hide-details
        dense
        class="field"
        @input="handleChangeValues"
      />

      <v-autocomplete
        v-if="field.type === 'select'"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        v-model="values[field.key]"
        :items="field.items"
        dense
        hide-no-data
        hide-details
        color="primary"
        item-text="name"
        item-value="id"
        @change="handleChangeValues"
        outlined
        style="margin: 10px;"
      />
      <form-item-allergens 
        v-if="field.type === 'multiselect' && field.key === 'allergens'"
        :field="field"
        :value="values[field.key]"
        :locale="locale"
        :onChange="handleChangeAllergens"
        style="margin: 10px"
      />
      <v-autocomplete
        v-if="field.type === 'boolean' && sBooleanValues"
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        v-model="values[field.key]"
        :items="sBooleanValues"
        dense
        hide-no-data
        hide-details
        color="primary"
        item-text="name"
        item-value="id"
        @change="handleChangeValues"
        outlined
        style="margin: 10px;"
      />
      <v-autocomplete
        v-if="field.type === 'autocomplete'"
        v-model="values[field.key]"
        :items="field.items"
        outlined
        filled
        color="primary"
        chips
        :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
        item-text="name"
        item-value="id"
        @change="handleChangeValues"
        style="margin: 10px;"
        hideDetails
        dense
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
          >
            <v-avatar left v-if="data.item.avatar">
              <v-img :src="data.item.avatar"></v-img>
            </v-avatar>
            {{ getTranslation(data.item.name, locale) }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">s
            <v-list-item-content >
              <div style="padding-left: 20px; color: red;">{{data.item}}</div>
            </v-list-item-content>
          </template>
          <template v-if="data.item.name">
            <!--v-list-item-avatar v-if="data.item.avatar">
              <img :src="data.item.avatar">
            </v-list-item-avatar-->
            <v-list-item-content>
              <v-list-item-title v-html="getTranslation(data.item.name, locale)"></v-list-item-title>
              <v-list-item-subtitle v-html="getTranslation(data.item.group, locale)"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <div
        v-if="field.type === 'birthday'"
        style="padding: 10px !important;"
      >
        <form-item-birthday
          :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
          :onChange="(v) => values[field.key] = v"
          :data="values[field.key]"
        />
      </div>
        <!--v-autocomplete
              v-if="field.type === 'multiselect'"
              v-model="values[field.key]"
              :items="field.items"
              filled
              chips
              color="blue-grey lighten-2"
              item-text="name"
              item-value="id"
              :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.avatar"></v-img>
                  </v-avatar>
                  {{ getTranslation(data.item.name, locale) }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="data.item.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="getTranslation(data.item.name, locale)"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete-->
      <p v-if="errorFieldsRequired.indexOf(field.id) >= 0" style="margin: -5px 0 0 0 !important;padding-left:20px;font-size:0.7rem;color:red;">
        {{ getTranslation(field.label, locale) }} {{ $t('booking.reservations.messages.fieldRequired', locale).toLowerCase() }} *
      </p>
      <p v-if="errorFieldsNotValid.indexOf(field.id) >= 0" style="margin: -5px 0 0 0 !important;padding-left:20px;font-size:0.7rem;color:red;">
        {{ getTranslation(field.label, locale) }} {{ $t('booking.reservations.messages.fieldNotValid', locale).toLowerCase() }}
      </p>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import FormItemAllergens from './FormItemAllergens'
import FormItemBirthday from './FormItemBirthday'
import CheckInfo from './CheckInfo'
export default {
  name: 'OwnerPaxFormItem',
  components: {
    FormItemAllergens,
    FormItemBirthday,
    CheckInfo,
  },
  props: {
    formFields: {
      type: Array,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    pos: {
      type: Number,
      required: false,
    },
    onDeletePax: {
      type: Function,
      default: null
    },
    onChangeValues: {
      type: Function,
      required: true,
    },

    errorFieldsRequired: {
      type: Array,
      default: null,
    },
    errorFieldsNotValid: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    values: {},
    sBooleanValues: null,
    booleanValues: [{
      id: null,
      name: { es: ' ', en: ' ' },
    },{
      id: true,
      name: { es: 'SI', en: 'YES' }
    },{
      id: false,
      name: { es: 'NO', en: 'NO' }
    }]
      
  }),
  computed: {        
    ...mapState('app', ['locale']),
    isDev () {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  watch: {
    formFields () {
      this.prepareValues()
    },
    locale () {
      this.prepareBooleanValues()
    },
  },
  mounted () {
    this.prepareBooleanValues()
    this.prepareValues()
  },
  methods: {
    prepareGroup (v) {
      console.log(v)
    },
    prepareBooleanValues() {
      this.sBooleanValues = this.booleanValues.map(item => {
        return {
          id: item.id,
          name: utils.getTranslation(item.name, this.locale)
        }
      })
    },
    prepareValues () {
      this.values = {}
      for (const field of this.formFields) {
        if (field.type === 'autocomplete') {
          field.items = field.items.map(item => {
            if (item.header)item.header = this.getTranslation(item.header, this.locale)
            return item
          })
        }
        this.values[field.key] = field.defaultValue
        if (this.isDev && !this.values[field.key]) {
          const key = field.key
          if (field.type === 'email') this.values[field.key] = 'test@descubregroup.com'
          if (key === 'name') this.values[field.key] = 'test'
          if (key === 'allergens') this.values[field.key] = ["Sin alergias"]
        }
      }
      this.handleChangeValues()
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleChangeValues () {
      // if email remove spaces
      for (const item of this.formFields.filter(x => x.type === 'email' && this.values[x.key])) {
        this.values[item.key] = this.values[item.key].split(' ').join('')
      }
      this.onChangeValues(this.isOwner, this.values, this.pos)
    },
    handleChangeAllergens (key, v) {
      this.values[key] = v
      this.handleChangeValues()
    },
    handleCheckInfo(key, v) {
      this.values[key] = v
      this.handleChangeValues()
    },
  }
}
</script>
<style scoped>
.owner {
}
.pax {
  background-color: #CCCCCC80;
  border-radius: 5px;
  margin-top: 10px;
}
.field {
  margin: 10px 10px;
}
.v-subheader {
  background-color:gray !important;
}
</style>

