<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="width:100%;"
    >
      <template v-slot:activator="{ on, attrs }">
        <div style="padding:20px;width: 100%">
          <v-btn
            v-if="reservation && reservation.Status !== 'CANCELED'"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            outlined
            block
            style="width:100%;"
          >
            {{ $t('booking.reservations.moveReservation', locale) }}
          </v-btn>
        </div>
      </template>
      <v-card style="background-color:white;">
        <v-toolbar
          dark
          color="primary"
          elevation="0"
          style="border-radius: 0"
        >
          <v-btn
            icon
            dark
            @click="handleClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('booking.reservations.moveReservation', locale) }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleClose"
            >{{$t('common.close', locale)}}</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout wrap>
          <v-flex xs4 style="padding: 20px; text-align: center;">
            <v-date-picker
              :key="selectedDate"
              v-model="selectedDate"
              :first-day-of-week="0"
              :locale="locale"
              :event-color="checkEventsColors"
              :events="checkEvents"
              :picker-date.sync="pickerDate"
              flat
              style="border-radius: 4px 0 0 0;"
            />
          </v-flex>
          <v-flex xs8 style="padding: 20px;">
            <confirm-dialog
              :show="showConfirmDialog"
              :locale="locale"
              :onOK="handleMoveReservation"
              :onKO="handleCloseConfirmDialog"
            />
            <div
              v-for="event of eventsFiltered"
              :key="event.ID"
              color="primary"
              style="float:left; padding:5px;"
            >
            <v-btn
              @click="handleSelectEvent(event.ID)"
              :color="event.ID === reservation.EventID ? 'green' : event.Free < reservation.NumPax ? 'red' : 'primary'"
              :disabled="loading || event.ID === reservation.EventID || event.Free < reservation.NumPax"
            >
                {{convertTime(event.Time)}} ({{event.Free}})
            </v-btn>
            </div>
            <br/>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ConfirmDialog from './ConfirmDialog'
export default {
  components: {
    ConfirmDialog,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    selectedDate: null,//utils.checkDate(new Date()),
    pickerDate: null,
    activeDates: [],
    events: [],
    eventsFiltered: [],

    reservation: null,
    showConfirmDialog: false,
    selectedEventID: null,
  }),
  computed: {
    ...mapState('app',['bookingWorkspace', 'bookingRefreshReservationList']),
    workspaceID () {
      return this.bookingWorkspace
    }
  },
  watch: {
    editedID () {
      this.handleGetReservation()
    },
    dialog (v) {
      if (!v) return
      this.handleGetActiveDates()
    },
    selectedDate () {
      this.handleGetEvents()
    },
    'reservation.ActivityID' () {
      this.handleGetEvents()
    },
  },
  mounted () {
    this.handleGetReservation()
    this.pickerDate = new Date().toISOString().substring(0, 7)
    this.handleGetActiveDates()
  },
  methods: {
    handleClose () {
      this.dialog = false
    },
    async handleGetReservation () {
      try {
        if (!this.editedID) return 
        this.reservation = await new Promise((resolve, reject) => api.getItem('booking', `v1/private/reservations/`, this.editedID)
          .then(response => {
            resolve(response ? {
              ActivityID: response.ActivityID,
              NumPax: response.NumPax, 
              Status: response.Status,
              EventID: response.BookedEventID,
              response
            } : null)
          })
          .catch(error => {
            reject(error)
          }))
      } catch (error) {
        console.log(error)
      }
    },
    handleGetActiveDates () {
      this.activeDates = []
      if (!this.dialog || !this.workspaceID || !this.pickerDate) return
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/reserved-days`, false, `&date=${this.pickerDate}`)
        .then(response => {
          this.selectedDate = utils.checkDate(new Date())
          this.activeDates = response.map(item => {
            return {
              date: item.Date.split('T').shift(),
              book: item.TotalBook && item.TotalBook > 0,
              free: item.TotalFree && item.TotalFree > 0,
              cancel: item.TotalCancel && item.TotalCancel > 0,
            }
          })
        })
    },
    checkEvents (date) {
      if (!this.activeDates) return false
      const aux = this.activeDates.filter(x => x.date === date).shift()
      if (!aux) return false

      const result = []
      if (!aux.free && aux.book) result.push('#000000')
      else if (aux.book) result.push('green')
      if (aux.cancel) result.push('red')
      if (!aux.book && !aux.cancel) result.push('#CCCCCC')
      return result.length > 0 ? result : false
      /*
      const [,, day] = date.split('-')
      if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', 'green']
      return false
      */
    },
    checkEventsColors () {
      return null
      //return date[9] % 2 ? 'red' : 'yellow'
    },
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    handleGetEvents () {
      this.handleCloseConfirmDialog()
      this.eventsFiltered = []
      if (!this.reservation || !this.reservation.ActivityID) return
      if (!this.selectedDate && this.workspaceID && this.dialog) return
      this.loading = true
      const filter = `?min=${this.selectedDate}&max=${this.selectedDate}&excludeHide=true&`
      api.getAllWithoutLimit('booking', `v1/public/workspaces/${this.workspaceID}/events${filter}`)
        .then(response => {
          if (response) {
            //TODO check if correct
            response = response.filter(x => x.AllowReservations)
            if (this.selectedDate === utils.checkDate(new Date())) 
              this.events = response.filter(x => x.AllowBook && x.Time >= ((new Date().getHours() * 60) + new Date().getMinutes()))
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)
            else if (new Date() > new Date(this.selectedDate)) this.events = []
            else this.events = response.sort((a, b) => a.ActivityID-b.ActivityID)
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)

            //TODO tmp
this.events = response.sort((a, b) => a.ActivityID-b.ActivityID)
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)


            //TODO tmp
              this.eventsFiltered = this.events.filter(x => x.ActivityID === this.reservation.ActivityID)
          } else {
            this.events = []
            this.activities = []
          }
          this.loading = false
        })
    },
    handleSelectEvent (v) {
      this.selectedEventID = v
      this.showConfirmDialog = true
    },
    handleCloseConfirmDialog () {
      this.selectedEventID = null
      this.showConfirmDialog = false
    },
    handleMoveReservation () {
      this.loading = true
      const body = {
        ReservationID: this.editedID,
        OldEventID: this.reservation.EventID,
        NewEventID: this.selectedEventID
      }

      api.updateItem('booking', `v1/private/reservations/`, `${this.editedID}/move-reservation/`, body)
        .then (response => {
          if (response.data.status === 'OK') {
            this.$store.state.app.bookingRefreshReservationList = new Date().toISOString()
            this.loading = false
            this.handleCloseConfirmDialog()
            this.dialog = false
            this.onClose()
          }
        })
        .catch(error => {
          console.log(error)
          alert('error')
          this.loading = false
        })
    },
  },
}
</script>

