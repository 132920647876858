<template>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          hide-details
          dense
        />
      </template>
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
      />
    </v-menu>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      default: null,
    },
    data: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
  }),
  watch: {
    data () {
      this.prepareDate()
    },
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted () {
    this.prepareDate()
  },
  methods: {
    prepareDate () {
      if (this.data) this.date = this.data
    },
    save (date) {
      this.$refs.menu.save(date)
      if (this.onChange) this.onChange(date)
    },
  },
}
</script>

