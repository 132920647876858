<template>
  <v-row style="padding-left: 20px;">
    <v-dialog
      v-model="dialogTerms"
      scrollable
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <table>
          <tr>
            <td>
              <v-checkbox 
                v-model="check"
                @change="handleChange"
              />
            </td>
            <td>
              <a v-bind="attrs" v-on="on" style="color:black;">
                {{getTranslation(field.label, locale)}} {{ field.required ? ' (*)' : ''}}
              </a>
            </td>
          </tr>
        </table>
        <!--v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn-->
      </template>
      <v-card>
        <v-card-title>
          {{getTranslation(field.label, locale)}}
        </v-card-title>
        <v-divider />
        <v-card-text style="height: 300px;">
          {{ getTranslation(field.info, locale) }}
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogTerms = false"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            text
            @click="handleOK"
          >
            {{ $t('common.ok', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    color: {
      type: String,
      default: 'primary'
    },
    onChange: {
      type: Function,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogTerms: false,
    check: false,
  }),
  watch: {
    value () {
      this.prepareValue()
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      if (this.value !== this.check) this.check = this.value
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleChange () {
      this.onChange(this.check)
    },
    handleOK () {
      this.check = true
      this.onChange(this.check)
      this.dialogTerms = false
    },
  }
}
</script>


