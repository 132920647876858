<template>
  <v-layout wrap class="form-menu">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-bookmark</v-icon>
      <v-toolbar-title>
        {{ $t(`booking.reservations.${isNew ? 'addReservation' : 'editReservation'}`, locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12>
      <owner-pax-form-item
        v-if="formFields && formFields.owner"
        :formFields="formFields.owner"
        :isOwner="true"
        :onChangeValues="handleChangeValues"
        :errorFieldsRequired="errorFieldsRequired"
        :errorFieldsNotValid="errorFieldsNotValid"
      />
    </v-flex>
    <template v-if="seatsType === 'byResources'">
      <resource-selector
        :reservationID="editedID"
        :eventID="event.ID"
        :onChange="handleChangeResource"
        :locale="locale"
        :selectedResourceID="selectedResourceID"
      />
    </template>
    <template v-else-if="formFields.paxAsCount">
      <custom-pax-count-config
        :max="max"
        :eventID="event.ID"
        :locale="locale"
        :onChange="handleChangePaxData"
        :oldAdult="oldAdult"
        :oldChildren="oldChildren"
        :oldBaby="oldBaby"
        :oldNumPax="paxCount"
      />
      <!--custom-text-field-number
        v-model="paxCount"
        :max="max"
        dense
        :label="$t('booking.reservations.numPax', locale)"
        style="padding: 10px"
      /-->
        <br/>
        <small style="width: 100%;text-align:right;color:gray;padding-right:20px;font-style: italic;"> ({{max}} max.)</small>
        <small v-if="min" style="width: 100%;text-align:right;color:gray;padding-right:20px;font-style: italic;"> ({{min}} min.)</small>
    </template>
    <template v-else>
    <v-flex xs12 v-if="pax && pax.length > 0">
      {{ $t('booking.reservations.paxData', locale) }} :
    </v-flex>
    <v-flex xs12 v-if="pax && pax.length > 0">
      <owner-pax-form-item
        v-for="(p, index) of pax"
        :key="`pax${index}`"
        :formFields="p"
        :pos="index"
        :onDeletePax="index === (pax.length -1) ? handleDeletePax : null"
        :onChangeValues="handleChangeValues"
        :errorFieldsRequired="errorFieldsRequired"
        :errorFieldsNotValid="errorFieldsNotValid"
      />
    </v-flex>
    <v-flex xs12 style="margin: 10px" v-if="formFields && formFields.pax && formFields.pax.length > 0">
      <v-btn 
        v-if="pax.length < event.Availability && pax.length < event.SeatsByBooking"
        :color="color ? color : 'primary'" 
        @click="handleAddPax" 
        dark 
      >
        {{ $t('booking.reservations.addPax', locale) }}
      </v-btn>
    </v-flex>
    </template>
    <v-flex xs12>
      <multi-event :activityID="event.ActivityID" :onChange="handleChangeMultiEvent"/>
    </v-flex>
    <v-flex xs12>
      <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          :color="color ? color : 'primary'"
          dark
        >
          <v-card-text>
            {{$t('booking.pleaseStandBy', locale)}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
              label="adfasdfasdfasdf asdf asdf asdf asdf assdfa sdfassdfasdf asdf asf asf "
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <move-reservation
        v-if="isDview && editedID && !isNew"
        :editedID="editedID"
        :locale="locale"
        :onClose="onClose"
      />
      <action-buttons
        :id="editedID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        deleteLabel="booking.cancelReservation"
        cancelLabel="common.close"
        :locale="locale"
        :color="color"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultValues ={
  owner: [],
  pax: {},
}
import { mapState } from 'vuex'
import axios from 'axios'
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
//import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import OwnerPaxFormItem from './OwnerPaxFormItem'
import CustomPaxCountConfig from './CustomPaxCountConfig'
import MultiEvent from './MultiEvent'
import ResourceSelector from './ResourceSelector'
import MoveReservation from './moveReservation/Index'
export default {
  name: 'reservationForm',
  components: {
    ActionButtons,
    OwnerPaxFormItem,
    //CustomTextFieldNumber,
    CustomPaxCountConfig,
    MultiEvent,
    ResourceSelector,
    MoveReservation,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    token: {
      // only external  (update and delete)
      type: String,
      default: null
    },
    onClose: {
      type: Function,
      required: true
    },
    onAdd: {
      type: Function,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
    color: {
      type: String,
      deafult:null,
    },
  },
  data () {
    return {
      paxCount: 0,
      paxData: null,
      pax: [],
      loading: false,
      image: null,
      showSelectCustomer: false,
      formFields: [],
      values: defaultValues,
      oldNumPax: null, // only for updateItem

      oldAdult: null,
      oldChildren: null,
      oldBaby: null,

      errorFieldsRequired: [],
      errorFieldsNotValid: [],

      max: null,
      min: null,
      multiEventValue: null,
      selectedResourceID: null,
      seatsType: null,
    }
  },
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isNew () {
      return this.editedID === 'new'
    },
    isDview () {
      return this.$store.state.app.isDview
    },
    cacheFormData () {
      return this.$store.state.app.cacheFormData
    },
  },
  watch: {
    event () {
      this.handleGetData()
      //this.prepareFormFields()
    },
    editedID () {
      this.handleGetData()
      //this.handleGetItem()
    },
    'editedItem.AllowReservations' (v) {
      if (!v) this.editedItem.Seats = null
    },
    'event.Availability' () {
      this.prepareMax()
    },
    'event.SeatsByBooking' () {
      this.prepareMax()
    },
    paxCount () {
      //if (this.paxCount > this.max) this.paxCount = this.max
      if (this.paxCount < 0) this.paxCount = 0
    },
  },
  mounted () {
    //this.formFields = []
    this.handleGetData()
    //if (this.event) this.prepareFormFields()
    //if (this.editedID) this.handleGetItem()
    this.prepareMax()
  },
  methods: {
    prepareMax () {
      this.max = this.event.Availability < this.event.SeatsByBooking ? this.event.Availability : this.event.SeatsByBooking
      this.min = this.event.MinSeatsByBooking
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareReservation (formFields, response) {
      this.pax = []
      this.values = JSON.parse(response.FieldsValues)

      formFields.owner.map(item => {
        if (this.values.owner[item.key]) item.defaultValue = this.values.owner[item.key] // set default value with edited data
        return item
      })
      this.formFields = formFields
      this.oldNumPax = response.NumPax
      this.oldAdult = response.Adult
      this.oldChildren = response.Children
      this.oldBaby = response.Baby
      this.paxCount = this.oldNumPax
      for (let i = 0; i < this.oldNumPax; i++) this.handleAddPax(this.values.pax[i])
      this.selectedResourceID = response.ResourceItemID
      this.seatsType = response.SeatsType
      this.loading = false
    },
    prepareField (v) {
      if (this.editedItem && !this.isNew) {
        // console.log('jhm', v)
      }
      if(v.type === 'select' && v.items && v.items.length > 0) {
        v.items = v.items.map(item => {
          item.name = utils.getTranslation(item.name, this.locale)
          return item
        })
      }
      if (v.type === 'birthday') {
        v.min = v.min ? v.min | 0 : null
        v.max = v.max ? v.max | 0 : null
      }
      return v
    },
    handleGetData () {
      this.loading = true
      this.formFields = []
      this.values = defaultValues
      if (!this.event || !this.editedID) return

      this.seatsType = this.event.SeatsType

      // get form fields by eventID
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/events/${this.event.ID}/form-fields?type=${this.event.source||this.event.Source}`
      //api.getAllWithoutLimit ('booking', `v1/public/events/${this.event.ID}/${this.event.type === 'acttiv' ? 'form-fields-acttiv' : 'form-fields' }`)
      axios.get(url)
        .then(response => {
          if(!response.data) {
            this.loading = false
            return
          }

          if (response.data.owner) response.data.owner = response.data.owner.map(item => {
            item = this.prepareField(item)
            return item
          })
          if (response.data.pax) response.data.pax = response.data.pax.map(item => {
            item = this.prepareField(item)
            return item
          })
          //this.formFields = response
          this.handleGetItem(response.data)
        })
    },
    handleGetItem (formFields) {
      if (this.isNew) {
        if (this.cacheFormData && window.location.href.indexOf('.dtouch.') > 0) {
          formFields.owner = formFields.owner.map(item => {
            item.defaultValue = this.getStorage(`f_${item.key}`)
            return item
          })
        }
        this.formFields = formFields
        this.loading = false
        return
      }
      if (this.token) {
        const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/external/reservations/content-manager/${this.editedID}?token=${this.token}`
        axios.get(url)
          .then(response => {
            this.prepareReservation (formFields, response.data.reservation)
          })
      } else {
        api.getItem ('booking', 'v1/private/reservations/', this.editedID)
          .then(response => {
            this.prepareReservation(formFields, response)
          })

      /*
        this.pax = []
        api.getItem ('booking', 'v1/private/reservations/', this.editedID)
          .then(response => {
            this.values = JSON.parse(response.FieldsValues)

            formFields.owner.map(item => {
              if (this.values.owner[item.key]) item.defaultValue = this.values.owner[item.key] // set default value with edited data
              return item
            })
            this.formFields = formFields
            this.oldNumPax = response.NumPax
            for (let i = 0; i < this.oldNumPax; i++) this.handleAddPax(this.values.pax[i])
            
            this.loading = false
          })
          */
      }
    },
    handleAddPax (v) {
      if (!this.formFields.pax) return

      const formFieldsPax = JSON.parse(JSON.stringify(this.formFields.pax))

      if (v) for (const ff of formFieldsPax) {
        if (v[ff.key]) ff.defaultValue = v[ff.key] // set default value with edited data
        else {
          // if first pax set default value with owner data (if same key)
          if (this.pax.length === 0) ff.defaultValue = this.values.owner[ff.key]
        }
      }
    
      this.pax.push(formFieldsPax)
    },
    handleDeletePax(v) {
      this.pax.splice(v, 1)
      this.values.numPax = this.pax.length
    },
    handleChangeValues (isOwner, v, pos) {
      if (isOwner) this.values.owner = v
      else this.values.pax[pos] = v

      this.values.numPax = this.pax.length

      this.handleChangeIsModified()
      this.handlePrepareFirstPaxWithOwnerData()
    },
    handlePrepareFirstPaxWithOwnerData() {
      if (this.pax.length > 0) {
        for (let i = 0 ; i < this.pax[0].length ; i++) {
          const paxKey = this.pax[0][i].key
          const value = this.values.owner[paxKey]
          if (value) this.pax[0][i].ownerValue = value
        }
      }
    },
    handleChangeIsModified () {
    },
    checkValues () {
      let isValid = true
      this.errorFieldsRequired = []
      this.errorFieldsNotValid = []

      if (!this.formFields.paxAsCount) {
      // PAX
      for (let i = 0; i < this.pax.length; i++) {
        for (const field of this.formFields.pax.filter(x => x.required)) {
          if (!this.values.pax[i]) {
            this.errorFieldsRequired.push(field.id)
            isValid = false
          }
          let value = this.values.pax[i][field.key]
          if (!value || value.length === 0) {
            this.errorFieldsRequired.push(field.id)
            isValid = false
          }
          if (field.type === 'email') {
            value = value.split(' ').join('')
            if(!utils.validateEmail(value)) {
              this.errorFieldsNotValid.push(field.id)
              isValid = false
            }
          }
          if (field.type === 'birthday') {
            const aux = (new Date() - new Date(value))/(1000*60*60*24) 
            if ((field.max && aux > field.max) || (field.min && aux < field.min )) {
              this.errorFieldsNotValid.push(field.id)
              isValid = false
            }
          }
        }
      }
      }
      // OWNER
      for (const field of this.formFields.owner.filter(x => x.required)) {
        let value = this.values.owner[field.key]
        if (!value || value.length === 0) {
          this.errorFieldsRequired.push(field.id)
          isValid = false
        }
        if (field.type === 'email') {
          value = value.split(' ').join('')
          if (!utils.validateEmail(value)) {
            this.errorFieldsNotValid.push(field.id)
            isValid = false
          }
        }
      }


      return isValid
    },
    getStorage (v) {
      const aux = localStorage.getItem(v)
      return aux && aux.length > 0 ? aux : null
    },
    saveFormStorage (v) {
      if (window.location.href.indexOf('.dtouch.') <=0) return
      for (const key of Object.keys(v.owner)) {
        localStorage.setItem(`f_${key}`, v.owner[key])
      }
    },
    handleSave () {
      if (this.formFields.paxAsCount) {
        if (this.min && (this.paxCount < this.min ||this.paxCount <= 0)) {
          alert (`Debe existir al menos ${this.min} participante`)
          return
        } else if (this.paxCount <= 0) {
          alert ('Debe existir al menos un participante')
          return
        }

        if (this.paxCount > this.max) {
          alert ('El max. es ' + this.max)
          return
        }
      
        // TODO checkResource

        /*
        if (this.paxCount > this.event.SeatsByBooking) {
          alert ('El max. es ' + this.event.SeatsByBooking)
          return
        }
        */
        const pax = []
        for (let i = 0; i < this.paxCount; i++) {
          const aux = {}
          if (this.formFields.pax.length > 0)
            aux[this.formFields.pax[0].key] = `Pax ${i+1}`
          pax.push(aux)
        }
        this.values.pax = pax
        this.values.numPax = this.paxCount
      
      } else if (this.min && (this.paxCount < this.min ||this.paxCount <= 0)) {
          alert (`Debe existir al menos ${this.min} participante`)
          return
      } else if (!this.pax || this.pax.length === 0) {
        alert ('Debe existir al menos un participante')
        return
      }
      if (!this.checkValues()) {
        alert('Formulario con errores')
        return
      }
      this.values.paxData = this.paxData

      this.values.rate = this.isRate ? this.rate : null
      
      const body = {
        event: {
          EventID: this.event.ID, 
          Source: this.event.Source,//this.event.IsActtiv ? 'acttiv' : 'contentmanager', 
          WorkspaceID: this.workspaceID,
          ScheduleID: this.event.ScheduleID, 
          ActivityID: this.event.ActivityID, 
          ActivityName: this.event.ActivityName, 
          DateTime: this.event.DateTime, 
          Date: this.event.Date, 
          Time: this.event.Time, 
          Duration: this.event.Duration, 
          Free: this.event.Free, 
          Book: this.event.Book, 
          Cancel: this.event.Cancel,
          Seats: this.event.Seats,
          SeatsByBooking: this.event.SeatsByBooking
        },
        values: this.values,
        oldNumPax: this.oldNumPax,
        multiEventValue: this.multiEventValue,
        resourceID: this.selectedResourceID,
      }

      this.saveFormStorage(this.values)

      if (this.isNew) {
        if (this.onAdd) this.onAdd(body)
      } else {
        if (this.onUpdate) this.onUpdate(this.editedID, body)
      }
/*
      if (this.isNew)
        api.addItem ('booking', `v1/private/reservations/`, body)
          .then((response) => {
            console.log('response', response)
            this.onClose('add', response.data)
          })
      else 
        api.updateItem ('booking', `v1/private/reservations/`, this.editedID , body)
          .then((response) => {
            console.log('response', response)
            this.onClose('add', response.data)
          })*/
    },
    handleDelete () {
      if(this.onDelete) this.onDelete(this.editedID)
      /*
      api.deleteItem('booking', `v1/private/reservations/`, this.editedID)
        .then(response => {
          if(response && response.data.status === 'OK') this.onClose('delete', this.editedID)
        })
        */
    },
    handleChangePaxData (v) {
      this.paxCount = v.paxCount
      this.paxData = v
    },
    handleChangeMultiEvent (v) {
      this.multiEventValue = v
    },
    handleChangeResource (v) {
      if (v) {
        if (this.formFields.paxAsCount) {
          this.paxCount = 1
          this.paxData = {
            paxCount: 1,
            adult: 1
          }
        } 
        else if(this.pax.length === 0) this.handleAddPax()
      }
      this.selectedResourceID = v
    },
  },
}
</script>
<style scoped>
.form-menu .availability {
  background-color: #f3f0f0;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 15px;
}
.form-menu .common-forms {
  padding: 0 10px 10px 10px;
}
</style>


