<template>
  <v-dialog v-model="show" max-width="600px">      
    <v-card>
      <v-card-title class="headline">{{ $t('booking.messages.confirmMoveReservation', locale) }}</v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onKO">{{ $t('common.cancel', locale) }}</v-btn>
        <v-btn color="primary" dark @click="onOK">{{ $t('common.confirm', locale) }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      require: true,
    },
    locale: {
      type: String,
      requied: true
    },
    onOK: {
      type: Function,
      required: true,
    },
    onKO: {
      type: Function,
      required: true
    },
  },
}
</script>

