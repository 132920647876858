<template>
  <div v-if="maxRangeEventReservation" v-show="items && items.length > 1" style="padding: 10px;">
    <v-select
      :items="items"
      v-model="selectedItem"
      label="D�as a reservar"
      outlined
      hide-details
      dense
      @change="onChange"
    />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: {
    activityID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    maxRangeEventReservation: null,
    items: [],
    selectedItem: null,
  }),
  watch: {
    activityID () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (!this.activityID) return
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/cm/activities/${this.activityID}`
      axios.get(url)
        .then(response => {
          const aux = response.data.shift()
          this.maxRangeEventReservation = aux.MaxRageEventReservation
          if (this.maxRangeEventReservation) {
            for (let i = 1 ; i <= this.maxRangeEventReservation; i ++) {
              this.items.push(i)
            }
            if(this.items.length > 0) this.selectedItem = this.items[0]
          } else {
            this.onChange(null)
          }
        })
    },
  },
}
</script>

