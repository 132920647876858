<template>
  <v-autocomplete
              v-model="selectedAllergens"
              :items="allergens"
              filled
              chips
              color="blue-grey lighten-2"
              item-text="name"
              item-value="id"
              :label="getTranslation(field.label, locale).concat(field.required ? ' (*)' : '')"
              multiple
              hide-details
              menu-props="closeOnContentClick"
              @change="handleChange"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left v-if="data.item.avatar">
                    <v-img :src="data.item.avatar"></v-img>
                  </v-avatar>
                  {{ getTranslation(data.item.name, locale) }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar v-if="data.item.avatar">
                    <img :src="data.item.avatar">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="getTranslation(data.item.name, locale)"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [Array, String],
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    allergens: [],
    loading: false,
    selectedAllergens: [],
  }),
  watch: {
    value () {
      this.prepareValue ()
    },
  },
  mounted () {
    this.loading = true
    this.prepareValue ()
    this.handleGetAllergens ()
  },
  methods: {
    prepareValue () {
      if (!this.value) return
      if (typeof(this.value) === 'string')
        this.selectedAllergens = this.value.split(',')
      else this.selectedAllergens = this.value
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    remove (item) {
      const index = this.selectedAllergens.indexOf(item.id)
      if (index >= 0) this.selectedAllergens.splice(index, 1)
      this.handleChange()
    },
    handleGetAllergens () {
      const url = 'https://cm-dtouch-api.dtouch.es/v1/public/workspaces/dd/allergens'
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const aux = data.shift()
          if (aux) aux.data.unshift({ Name: { es: 'Sin alergias', en: 'No allergens' }, Image: null})
          this.allergens = aux ? aux.data.map(item => {
            return {
              id: item.Name.es,
              name: item.Name,
              avatar: item.Image ? `https://www.dtouch.es/assets/allergens/${item.Image}.png` : null
            }
          }) : []
          this.loading = false
        })
    },
    handleChange () {
      this.onChange(this.field.key, this.selectedAllergens)
    },
  }
}
</script>


