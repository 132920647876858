<template>
  <div
    v-if="resourcesItems"
    style="width:100%;padding: 10px;"
  >
    <resource-seat-map
      v-if="seatMapConfig"
      :config="seatMapConfig"
      :resourceItemID="resourceID"
      :onChangeSeatMapSelected="handleChangeSeatMapSelected"
    />
    <v-select
      v-model="resourceID"
      :items="resourcesItems"
      :label="`${getTranslation(resourceName, locale)} (*)`"
      outlined
      hide-details
      dense
      itemText="sName"
      itemValue="ID"
      @change="onChange"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import ResourceSeatMap from './ResourceSeatMap'
export default {
  components: {
    ResourceSeatMap,
  },
  props: {
    reservationID: {
      type: String,
      default: null,
    },
    eventID: {
      type: String,
      required: true,
    },
    selectedResourceID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    resourceName: null,
    resourcesItems: null,
    resourceID: null,

    seatMapConfig: null,
  }),
  computed: {
    ...mapState('app', ['bookingUpdateAvailability_resource']),
  },
  watch: {
    selectedResourceID () {
      this.prepareData()
    },
    bookingUpdateAvailability_resource () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareData () {
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/cm/events/${this.eventID}/available-resources/${this.reservationID ? this.reservationID : ''}`
      axios.get(url)
        .then(response => {
          this.resourceName = response.data.ResourceLabel
          this.resourcesItems = response.data.ResourceItems.map(item => {
            item.sName = utils.getTranslation(item.Name, this.locale)
            return item
          })
          if (response.data.IsSeatMap && response.data.SeatMapConfig && response.data.SeatMapConfig.AllResourceItem) {
            response.data.SeatMapConfig.AllResourceItem = response.data.SeatMapConfig.AllResourceItem.map(item => {
              item.Disabled = this.resourcesItems.map(item => item.ID).indexOf(item.ID) < 0
              return item
            })
          }
          this.seatMapConfig = response.data.IsSeatMap ? response.data.SeatMapConfig : null
          //this.resourceID = this.selectedResourceID
          this.resourceID = this.resourcesItems.filter(x => x.ID === this.selectedResourceID).length > 0 ? this.selectedResourceID : null
        })
    },
    handleChangeSeatMapSelected (v) {
      this.resourceID = v
      this.onChange(v)
    }
  },
}
</script>

