<template>
  <center id="seat-map">
    <div
      v-if="width"
      class="seat-map"
      :style="{ 
        height: `${getSize(768)}px`, 
        backgroundImage: config.SeatMapBackground ? `url(${config.SeatMapBackground})` : null,
      }"
    >
      <img
        v-for="item of config.AllResourceItem"
        :key="item.ID"
        :src="`${item.SeatMapImage.replace('.png', '')}${item.Disabled ? '_lock.png' : selectedItem === item.ID ? '_selected.png' : '.png'}`"
        style="position:absolute;"
        :style="{
          cursor: !item.Disabled ? 'pointer' : null,
          marginTop: `${getTop(item)}px`,
          marginLeft: `${getLeft(item)}px`,
          rotate: `${item.Angle}deg`,
          width: `${getWidth(item)}px`,
          height: `${getHeight(item)}px`,
          boderRadius: selectedItem === item.ID ? '5px' : '0',
        }"
        @click="handleSelect(item)"
      />
    </div>
    <br />
  </center>
</template>
<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    resourceItemID: {
      type: String,
      default: null,
    },
    onChangeSeatMapSelected: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    width: null,
    scale: null,
    selectedItem: null,
  }),
  watch: {
    resourceItemID (v) {
      if (v)
        this.selectedItem = v
    },
  },
  mounted () {
    const aux = document.getElementById('seat-map').offsetWidth
    this.width = aux > 1024 ? 1024 : aux

    this.selectedItem = this.resourceItemID
  },
  methods: {
    getSize (v) {
      return (v*this.width)/1024
    },
    getWidth (item) {
      return this.getSize(item.Width*item.ScaleX)
    },
    getHeight (item) {
      return this.getSize(item.Height*item.ScaleY)
    },
    getTop (item) {
     const aux = this.getSize(item.Top)//-(this.getWidth(item)/2)
      return aux < 0 ? 0 : aux
    },
    getLeft (item) {
      const aux = this.getSize(item.Left)//-(this.getHeight(item)/2)
      return aux < 0 ? 0 : aux
    },
    handleSelect (v) {
      if (v.Disabled) return
      this.selectedItem = v.ID
      this.onChangeSeatMapSelected(v.ID)
    },
  },
}
</script>
<style scoped>
.seat-map {
  max-width: 1024px; 
  width:100%;
  background-size: cover;
  text-align: left;
}
</style>

